<!--  -->
<template>
  <div class="homecont">
    <HeadNav></HeadNav>

    <div class="homeLun">
      <el-carousel :interval="3000" :height="550 +'px'">
        <el-carousel-item>
          <img class="cardImg"  src="../assets/some/5.jpg" alt="轮播图1" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="cardImg"  src="../assets/some/8.jpg" alt="轮播图2"/>
        </el-carousel-item>
        <el-carousel-item>
          <img class="cardImg"  src="../assets/some/9.jpg" alt="轮播图3" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="homeTui">
      <div class="homeTuiBox">
          <div class="homeTuiBoxTitle">
             - Equipment
          </div>
          <div class="homeTuiBoxCont">
            <div class="homeTuiBoxContleft" @click="navDetailFun(tableListUrl4)">
              <img class="homeTuiBoxContleftimg" :src="tableListUrl4.imgUrl || ''" alt="Equipment1"/>
              <div class="homeTuiBoxContleftFont">
                <div class="homeTuiBoxContleftFontTop">{{tableListUrl4.title || ''}}</div>
                <div class="homeTuiBoxContleftFontCen">{{tableListUrl4.productText || ''}}</div>
                <div class="homeTuiBoxContleftFontBtm">See more <i class="el-icon-arrow-right"></i></div>
              </div>
            </div>
            <div class="homeTuiBoxContleft homeTuiBoxContCenter" @click="navDetailFun(tableListUrl3)">
              <img class="homeTuiBoxContleftimg" :src="tableListUrl3.imgUrl || ''" alt="Equipment2"/>
              <div class="homeTuiBoxContleftFont">
                <div class="homeTuiBoxContleftFontTop">{{tableListUrl3.title}}</div>
                <div class="homeTuiBoxContleftFontCen">{{tableListUrl3.productText}}</div>
                <div class="homeTuiBoxContleftFontBtm">See more <i class="el-icon-arrow-right"></i></div>
              </div>
            </div>
            <div class="homeTuiBoxContleft homeTuiBoxContRigr">
              <div class="homeTuiBoxContRigtop"  @click="navDetailFun(tableListUrl2)">
                <img class="homeTuiBoxContleftimg homeTuiBoxContRigtopimg" :src="tableListUrl2.imgUrl || ''" alt="Equipment3"/>
                <div class="homeTuiBoxContleftFont">
                  <div class="homeTuiBoxContleftFontTop">{{tableListUrl2.title}}</div>
                  <div class="homeTuiBoxContleftFontCen newhomeTuiBoxContleftFontCen">{{tableListUrl2.productText}}</div>
                  <div class="homeTuiBoxContleftFontBtm">See more <i class="el-icon-arrow-right"></i></div>
                </div>
              </div>
              <div class="homeTuiBoxContRigtop newhomeTuiBoxContRigtop" @click="navDetailFun(tableListUrl1)">
                <img class="homeTuiBoxContleftimg homeTuiBoxContRigtopimg" :src="tableListUrl1.imgUrl || ''" alt="Equipment4"/>
                <div class="homeTuiBoxContleftFont">
                  <div class="homeTuiBoxContleftFontTop">{{tableListUrl1.title}}</div>
                  <div class="homeTuiBoxContleftFontCen newhomeTuiBoxContleftFontCen">{{tableListUrl1.productText}}</div>
                  <div class="homeTuiBoxContleftFontBtm">See more <i class="el-icon-arrow-right"></i></div>
                </div>

              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="homeTui newMaterials">
      <div class="homeTuiBox">
          <div class="homeTuiBoxTitle">
             - Materials
          </div>
          <div class="newMaterialsCont">
            <div class="newMaterialsCont-top" >
              <div class="newMaterialsCont-top-lft">
                <div class="newMaterialsCont-top-lft-title">
                  <span class="newMaterialsCont-top-lft-title-lfnt">High-purity Conductive SiC Powder</span>
                  <!-- <span class="newMaterialsCont-top-lft-title-cent">Conductive</span>
                  <span class="newMaterialsCont-top-lft-title-rgnt">SiC Powder</span> -->
                </div>
                <div class="newMaterialsCont-top-lft-cont">
                  <div class="newMaterialsCont-top-lft-cont-tp">
                     <strong> · </strong>Third generation semiconductor materials with superior perfor-mance
                  </div>
                  <div class="newMaterialsCont-top-lft-cont-tp">
                    <strong> · </strong>Purity reached 99.99999%
                  </div>
                  <div class="newMaterialsCont-top-lft-cont-tp">
                    <strong> · </strong>Including needle-like material and granular material
                  </div>
                  <div class="newMaterialsCont-top-lft-cont-tp">
                    <strong> · </strong> By Chemical Vapor Deposition (CVD), SiC powder is produced by decomposition reaction of silicon source and carbon source gas at high temperature. The obtained SiC powder has high purity and the granularity is uniform.
                  </div>
                </div>
              </div>
              <img class="newMaterialsCont-top-rig" src="../assets/some/7.png" alt="介绍"/>
            </div>
            <div class="newMaterialsCont-btm" @click="navSicDetailFun()">
              <span>Want to know more</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
      </div>
    </div>

    <div class="homeTui newTui">
      <div class="homeTuiBox">
          <div class="homeTuiBoxTitle">
             - Application case
          </div>
          <div class="newApplication">
            <div class="chanpinYing">
              <div class="chanpinYingBox" v-for="(item,index) in newtableList" :key="index" @click="navAnDetailFun(item)">
                <div class="chanpinYingBox-img">
                  <img :src="item.img" alt="Application" />
                </div>
                <div class="chanpinYingBox-font">
                  {{item.title}}
                  <!-- <div class="newchanpinYingBox">{{item.caseText}}</div> -->
                </div>
              </div>
            </div>
            
          </div>
      </div>
      <div class="newApplicationBtm"></div>
    </div>
    
    <!-- <div class="homeweCont newBoHeBox">
        <div class="homeTuiBoxTitle">- Partners</div>
        <img src="../assets/some/13.png"/>
    </div> -->


    <div class="homeTui newPartnersBox">
      <div class="homeTuiBox">
          <div class="homeTuiBoxTitle">
             - Partners
          </div>
          <img src="../assets/some/13.png" alt="Partners"/>
      </div>
    </div>








    

      <div class="homeTui newAbout">
        <div class="newAboutBox">
          <img src="../assets/some/19.png" alt="About"/>
          <div class="newAboutLeft">
            <div class="newAboutFont">
              <div class="homeTuiBoxTitle newAboutFontFlot">- About us</div>
            </div>
            
            <div class="newAboutLeftcont">
              Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, 
              focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. 
              We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency,
              focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.
            </div>
          </div>
        </div>
        <div class="newAboutBtn">
          <div class="newAboutBtnBox">
            <div class="newAboutBtnBox-title">R&D SERGTH</div>
            <div class="newAboutBtnBox-btm">Our production and research and development base is equipped with high standard testing and testing facilities, as well as modern production lines, to ensure that our products always maintain industry-leading quality and performance.</div>
          </div>
          <div class="newAboutBtnBox">
            <div class="newAboutBtnBox-title">SERVICE COMMINIENT</div>
            <div class="newAboutBtnBox-btm">We provide comprehensive pre-sales, sales, and after-sales services to ensure that customers receive the best user expe-rience and technical support.</div>
          </div>
        </div>
      </div>


    <Bottom></Bottom>
    <Sidebar></Sidebar>
  </div>
</template>

<script>
  import { APILogin } from "../api/APILogin";
import HeadNav from "../components/headNav";
import Bottom from "../components/bottom";
import Sidebar from "../components/sidebar";
export default {
  metaInfo: {
    title: 'home',
    meta: [
      {
        name: 'JUNKO NEW ENERGY',
        content: 'SiC powder,Induction Heating Machine,Induction Heating power supply,water Cooling system,Disk CNC quenching machine tool,quenching machine tool,High precision power supply'
      },
      {
        name: 'description',
        content: 'Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.'
      }
    ]
  },
  name: "Home",
  components: {
    HeadNav,
    Bottom,
    Sidebar,
  },
  data() {
    return {
      windowHeight: (document.documentElement.clientHeight - 100),   //实时屏幕高度
      newList: [
        {
          id: 1,
          name: '高纯sic粉',
          name2: '第三代半导体材料——SIC  高纯度、导电型',
          url: require("../assets/new/c1.jpeg"),
          showOverlay: false,
        }, {
          id: 2,
          name: '回转支承滚道数控机床成套设备',
          name2: 'Inclined vertical raceway CNC',
          url: require("../assets/new/c2.png"),
          showOverlay: false,
        },{
          id: 3,
          name: '双频电源',
          name2: 'Gears, sprockets, slewing supports, etc. need profiling heating;',
          url: require("../assets/new/c3.png"),
          showOverlay: false,
        },{
          id: 4,
          name: '便携式感应加热电源',
          name2: 'The intelligent digital induction heating power supply has a high-speed core processor of 100 megabytes，which can calculate and process all digital signals',
          url: require("../assets/new/c4.png"),
          showOverlay: false,
        },{
          id: 5,
          name: '晶体生长感应加热电源',
          name2: 'Specially designed for crystal growth furnace',
          url: require("../assets/new/c5.png"),
          showOverlay: false,
        },
      ],
      
      language: 'en',




      total: 0,
      currentPage: 1,
      pageSize: 5,
      tableList: [],
      tableListUrl1:'',
      tableListUrl2:'',
      tableListUrl3:'',
      tableListUrl4:'',
      newpageSize: 6,
      newtableList: [],
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    // windowHeight (val) {
    //   let that = this;
    //  // console.log("实时屏幕高度：",val, that.windowHeight );
    // },
    
  },
  filters: {},
  //方法集合
  methods: {

    // 跳转进产品详情
    navDetailFun(item) {
      //console.log("跳转进产品详情item>>>>>",JSON.stringify(item.categoryId))
      this.$router.push({path: './homeChan', query: {typeVal: item.id}})
    },
    // 跳转进sic详情
    navSicDetailFun() {
      this.$router.push({path: './homeChan'})
    },
    // 跳转案例详情
    navAnDetailFun(item) {
      this.$router.push({path: './homeYing', query: {typeVal: item.id}})
    },

    handleMouseEnter(item) {
      //console.log("111>>>>",JSON.stringify(item.id))
      let idval = item.id
      this.newList.map(it=>{
        if(it.id == idval) {
          it.showOverlay = true
        }else {
          it.showOverlay = false
        }
      })
    },
    handleMouseLeave() {
      this.newList.map(item=>{
        item.showOverlay = false
      })
    },


    choseClick(item) {
        //console.log(">>>>>>>",JSON.stringify(item))
        this.$router.push({ 
            path: '/pcyaoqwenDetails', 
            query: { 
                auditID: item.id,  
            } 
        });
    },

    // 获取产品列表数据
    getDataList() {
        APILogin.getChanList({
            page: this.currentPage, // 分页参几页
            limit: 500000, // 分页参每页几条
            categoryId: '', // 分类id
        }).then(res => {
            // let b = res.data.data
            // b.map(item=>{
            //   item.imgsUrl = item.imgs.split(',')
            // })
            // this.tableList = b.slice(-4) 
            //console.log("获取产品列表数据>>>>" + JSON.stringify(b))
            let c = res.data.data.filter(item => item.top == '1')
            c.map(item=>{
              item.imgsUrl = item.imgs.split(',')
            })
            this.tableList = c.splice(-4)
            this.tableListUrl1 = this.tableList[0]
            this.tableListUrl1.imgUrl = this.tableList[0].imgsUrl[0] || ''

            this.tableListUrl2 = this.tableList[1] || ''
            this.tableListUrl2.imgUrl = this.tableList[1].imgsUrl[0] || ''

            this.tableListUrl3 = this.tableList[2] || ''
            this.tableListUrl3.imgUrl = this.tableList[2].imgsUrl[0] || ''

            this.tableListUrl4 = this.tableList[3] || ''
            this.tableListUrl4.imgUrl = this.tableList[3].imgsUrl[0] || ''
            // console.log(" this.tableListUrl4.imgUrl33>>>>" + JSON.stringify( this.tableListUrl4.imgUrl))

          }).catch(fail => {
            console.log("fail>>>>" + JSON.stringify(fail))
        })
    },
        // 获取案例列表数据
        newgetDataList() {
          APILogin.getAnliChanList({
              page: this.currentPage, // 分页参几页
              limit: 500000, // 分页参每页几条
              categoryId: '', // 分类id
          }).then(res => {
            let c = res.data.data.filter(item => item.top == '1')
            let newtableListArr = c.slice(-6) 
            this.newtableList = newtableListArr
            //this.newtableList = newtableListArr.reverse()
            // console.log("11111>>>>" + JSON.stringify(this.tableList))
          }).catch(fail => {
              console.log("fail>>>>" + JSON.stringify(fail))
          })
        },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // document.title = "纯子能源"
    this.getDataList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
    this.newgetDataList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped src="@/styles/pc/home.less"></style>
<style lang="less">
.newPartnersBox {
  margin-top: -200px;
  img {
    width: 80%;
    margin: 0 auto;
  }
}
.el-menu-item i {
  color: #fff !important;
}
  .chanpinBoxTitleTop {
    .el-carousel__item:hover .chanpinBoxContShuo{
        cursor: pointer;
        opacity: 1;
        border: 1px solid rgba(0, 0, 0,.3);
    }
    .is-active {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.6);
      border-radius: 5px;
      
    }
    .el-carousel__indicators--outside {
      visibility: hidden;
    }
  }

</style>



